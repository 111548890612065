import React, { useState, useEffect } from 'react';

import { changeStateByNestedKey } from '@actions/fugaActions';
import TextFieldWrapper from '@common/MuiWrappers/TextFieldWrapper';
import Grid from '@mui/material/Grid2';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const ClientDetailsContent = () => {
    const dispatch = useDispatch();
    const rhDataModel = useSelector((state) => state.fugaReducers.rhData);
    const { detailsData } = rhDataModel;
    const params = useParams();
    const areFieldsDisabled = useSelector(
        (state) => state.fugaReducers.clientPage.areFieldsDisabled,
    );

    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        dispatch(changeStateByNestedKey('clientPage', 'isEditBtnVisible', true));
        if (params.id === '-1' || !areFieldsDisabled) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [areFieldsDisabled, params.id]);

    useEffect(() => {
        dispatch(
            changeStateByNestedKey('rhData', 'mainDetailsInptValues', {
                ...rhDataModel.mainDetailsInptValues,
                clientName: detailsData.legalCompanyName,
                businessRegisteredAddress: detailsData.businessRegisteredAddress,
                businessMailingAddress: detailsData.businessMailingAddress,
                subLabels: detailsData.subLabels,
                tier: detailsData.tier,
            }),
        );
    }, [rhDataModel.detailsData]);

    const handleInputChange = (event) => {
        dispatch(
            changeStateByNestedKey('rhData', 'mainDetailsInptValues', {
                ...rhDataModel.mainDetailsInptValues,
                [event.target.name]: event.currentTarget.value,
            }),
        );
    };

    const handleTierValueChange = (e) => {
        const value = e.target.value;
        dispatch(
            changeStateByNestedKey('rhData', 'mainDetailsInptValues', {
                ...rhDataModel.mainDetailsInptValues,
                tier: value,
            }),
        );
    };

    return (
        <Grid container rowSpacing={1} columnSpacing={4}>
            <Grid size={6}>
                <TextFieldWrapper
                    label="Client Name/Legal Company"
                    required
                    onChange={(evt) => handleInputChange(evt)}
                    name="clientName"
                    type="text"
                    fullWidth
                    InputProps={{
                        readOnly: isDisabled,
                    }}
                    value={rhDataModel.mainDetailsInptValues.clientName || ''}
                    inputProps={{ tabIndex: '1' }}
                />
            </Grid>

            <Grid size={6} />

            <Grid size={6}>
                <TextFieldWrapper
                    label="Business Registered Address"
                    onChange={(evt) => handleInputChange(evt)}
                    name="businessRegisteredAddress"
                    type="text"
                    fullWidth
                    multiline
                    rows={4}
                    InputProps={{
                        readOnly: isDisabled,
                    }}
                    value={rhDataModel.mainDetailsInptValues.businessRegisteredAddress || ''}
                    inputProps={{ tabIndex: '2' }}
                />
            </Grid>
            <Grid size={6}>
                <TextFieldWrapper
                    inputProps={{ tabIndex: '3' }}
                    label="Business Mailing Address"
                    onChange={(evt) => handleInputChange(evt)}
                    name="businessMailingAddress"
                    type="text"
                    fullWidth
                    multiline
                    rows={4}
                    InputProps={{
                        readOnly: isDisabled,
                    }}
                    value={rhDataModel.mainDetailsInptValues.businessMailingAddress || ''}
                />
            </Grid>
            <Grid size={6}>
                <TextFieldWrapper
                    label="Tier"
                    type="number"
                    fullWidth
                    onChange={handleTierValueChange}
                    value={rhDataModel.mainDetailsInptValues.tier || ''}
                    inputProps={{
                        tabIndex: '4',
                        min: 0,
                        max: 4,
                        step: 1,
                    }}
                />
            </Grid>
            <Grid size={6}>
                <TextFieldWrapper
                    inputProps={{ tabIndex: '5' }}
                    label="Sub-labels"
                    onChange={(evt) => handleInputChange(evt)}
                    name="subLabels"
                    type="text"
                    fullWidth
                    InputProps={{
                        readOnly: isDisabled,
                    }}
                    value={rhDataModel.mainDetailsInptValues?.subLabels || ''}
                />
            </Grid>
        </Grid>
    );
};

export default ClientDetailsContent;
