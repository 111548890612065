import React, { useState, useEffect } from 'react';

import { changeStateByNestedKey } from '@actions/fugaActions';
import TextFieldWrapper from '@common/MuiWrappers/TextFieldWrapper';
import { FormControlLabel, Checkbox, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

const PerformerDetailsContent = (props) => {
    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(true);
    const performersModel = useSelector((state) => state.fugaReducers.performers);
    const { byClietnId } = performersModel;

    useEffect(() => {
        if (performersModel.isInEditMode || performersModel.isInSaveNewMode) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [performersModel.isInEditMode, performersModel.isInSaveNewMode]);

    useEffect(() => {
        dispatch(changeStateByNestedKey('performers', 'isEditBtnVisible', true));
        if (props.match.params.id === '-1') {
            setIsDisabled(false);
        }
    }, []);

    useEffect(() => {
        const { mainDetailsInptValues, isInSaveNewMode, selectedIndex } = performersModel;

        dispatch(
            changeStateByNestedKey('performers', 'mainDetailsInptValues', {
                ...mainDetailsInptValues,
                firstName: isInSaveNewMode && selectedIndex < 0 ? '' : byClietnId.firstName,
                aliases: byClietnId.aliases || '',
                tier: byClietnId.tier !== undefined ? byClietnId.tier.toString() : '',
                surname: isInSaveNewMode && selectedIndex < 0 ? '' : byClietnId.lastName,
                artistName: byClietnId.artistNames || '',
                mainArtist: byClietnId.isMainArtist,
                businessMailingAddress: byClietnId.businessMailingAddress,
                businessRegisteredAddress: byClietnId.businessRegisteredAddress,
                sessionArtist: byClietnId.isSessionArtist,
            }),
        );
    }, [byClietnId]);

    const handleInputChange = (event) => {
        dispatch(
            changeStateByNestedKey('performers', 'mainDetailsInptValues', {
                ...performersModel.mainDetailsInptValues,
                [event.target.name]: event.currentTarget.value,
            }),
        );
    };

    const handleArtistTypes = (event, key) => {
        dispatch(
            changeStateByNestedKey('performers', 'mainDetailsInptValues', {
                ...performersModel.mainDetailsInptValues,
                [key]: event.target.checked,
            }),
        );
    };

    return (
        <Grid container rowSpacing={1} columnSpacing={4}>
            <Grid
                size={{
                    xs: 6,
                    md: 6,
                }}
            >
                <TextFieldWrapper
                    label="First name(s)"
                    fullWidth
                    required
                    onChange={(evt) => handleInputChange(evt)}
                    name="firstName"
                    type="text"
                    value={performersModel.mainDetailsInptValues.firstName ?? ''}
                    inputProps={{ tabIndex: '1' }}
                    InputProps={{
                        readOnly: isDisabled,
                    }}
                />
            </Grid>
            <Grid size={6}>
                <TextFieldWrapper
                    inputProps={{ tabIndex: '2' }}
                    label="Surname"
                    required
                    fullWidth
                    onChange={(evt) => handleInputChange(evt)}
                    name="surname"
                    type="text"
                    InputProps={{
                        readOnly: isDisabled,
                    }}
                    value={performersModel.mainDetailsInptValues.surname ?? ''}
                />
            </Grid>
            <Grid size={6}>
                <TextFieldWrapper
                    inputProps={{ tabIndex: '3' }}
                    label="Aliases"
                    fullWidth
                    onChange={(evt) => handleInputChange(evt)}
                    name="aliases"
                    type="text"
                    InputProps={{
                        readOnly: isDisabled,
                    }}
                    value={performersModel.mainDetailsInptValues.aliases ?? ''}
                />
            </Grid>
            <Grid size={6}>
                <TextFieldWrapper
                    inputProps={{ tabIndex: '4' }}
                    label="Artist name(s)"
                    onChange={(evt) => handleInputChange(evt)}
                    name="artistName"
                    fullWidth
                    type="text"
                    InputProps={{
                        readOnly: isDisabled,
                    }}
                    value={performersModel.mainDetailsInptValues.artistName ?? ''}
                />
            </Grid>
            <Grid size={6}>
                <TextFieldWrapper
                    label="Business Registered Address"
                    onChange={(evt) => handleInputChange(evt)}
                    name="businessRegisteredAddress"
                    type="text"
                    fullWidth
                    multiline
                    rows={4}
                    InputProps={{
                        readOnly: isDisabled,
                    }}
                    value={performersModel.mainDetailsInptValues.businessRegisteredAddress ?? ''}
                    inputProps={{ tabIndex: '2' }}
                />
            </Grid>
            <Grid size={6}>
                <TextFieldWrapper
                    inputProps={{ tabIndex: '3' }}
                    label="Business Mailing Address"
                    onChange={(evt) => handleInputChange(evt)}
                    name="businessMailingAddress"
                    type="text"
                    fullWidth
                    multiline
                    rows={4}
                    InputProps={{
                        readOnly: isDisabled,
                    }}
                    value={performersModel.mainDetailsInptValues.businessMailingAddress ?? ''}
                />
            </Grid>
            <Grid size={6}>
                <TextFieldWrapper
                    inputProps={{ tabIndex: '5' }}
                    label="Tier"
                    onChange={(evt) => handleInputChange(evt)}
                    name="tier"
                    type="text"
                    fullWidth
                    InputProps={{
                        readOnly: isDisabled,
                    }}
                    value={performersModel.mainDetailsInptValues.tier ?? ''}
                />
            </Grid>
            <Grid size={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                <FormControlLabel control={<Typography />} label="Artist type" color="primary" />
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={(e) => {
                                if (!isDisabled) {
                                    handleArtistTypes(e, 'mainArtist');
                                }
                            }}
                            checked={!!performersModel.mainDetailsInptValues.mainArtist}
                            color="primary"
                            tabIndex="6"
                        />
                    }
                    label="Main artist"
                    color="primary"
                />
                <FormControlLabel
                    color="primary"
                    control={
                        <Checkbox
                            color="primary"
                            onChange={(e) => {
                                if (!isDisabled) {
                                    handleArtistTypes(e, 'sessionArtist');
                                }
                            }}
                            tabIndex="7"
                            checked={!!performersModel.mainDetailsInptValues.sessionArtist}
                        />
                    }
                    label="Session artist"
                />
            </Grid>
        </Grid>
    );
};

const withUrlPerformerDetailsContent = withRouter(PerformerDetailsContent);
export default connect((r) => r)(withUrlPerformerDetailsContent);
