import React, { useState, useEffect } from 'react';

import { ExpandMore } from '@mui/icons-material';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    TableFooter,
    TablePagination,
    TableSortLabel,
    CircularProgress,
    Checkbox,
    styled,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

import OverflowTip from './OverflowTip';

const useStyles = makeStyles((theme) => ({
    header: {
        '& th': {
            padding: '14px 16px',
            borderRight: 'none',
        },
    },
    collapsibleCell: {
        '& .MuiTableRow-root .MuiTableCell-root:first-child': {
            width: '1px',
            whiteSpace: 'nowrap',
        },
    },
    bodyRow: {
        '&:hover > *': {
            cursor: 'pointer',
            backgroundColor: '#F4F4F6',
        },
        '& td': {
            padding: '14px 16px',
            border: 'none',
            backgroundColor: '#FFFFFF',
        },
    },
    noDataBodyRow: {
        '& td': {
            padding: '15px 16px',
            border: 'none',
            backgroundColor: '#FFFFFF',
        },
    },
    bodyCell: {
        minWidth: '30px',
    },
    checboxRow: {
        padding: '0px 8px 0 0',
    },
    selectedCount: {
        border: 'none',
        '& p': {
            width: '130px',
        },
    },
    tableSortIcon: {
        width: '100%',
    },
    flexHorizontal_s_b: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    collapsed: {
        transform: 'rotate(270deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expanded: {
        transform: 'rotate(0deg)',
    },
    expandIcon: {
        width: '18px',
        height: '18px',
        marginRight: '16px',
    },
    cellWithCheckbox: {
        display: 'flex',
        alignItems: 'center',
    },
    headerWithAdditionalContent: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    headerCellFixed: {
        width: '200px',
    },
    loading: {
        position: 'absolute',
        width: '100vw,',
        left: 0,
        right: 0,
    },
    noLoading: {
        bottom: '60px',
    },
}));

const LoaderOverlayRow = styled(TableRow)(() => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    minHeight: '90px',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
}));

const LoaderOverlayCell = styled(TableCell)(() => ({
    border: 'none',
    textAlign: 'center',
    padding: 0,
    backgroundColor: 'transparent !important',
}));

const updateList = (dataArray, value) => {
    const selectedIndex = dataArray.findIndex((item) => item.id === value.id);
    return selectedIndex === -1
        ? [...dataArray, value]
        : [...dataArray.slice(0, selectedIndex), ...dataArray.slice(selectedIndex + 1)];
};

const updateListBulk = (dataArray, valueArray) => {
    const missingItems = valueArray.filter(
        (item) => !dataArray.some((data) => data.id === item.id),
    );
    return missingItems.length === 0
        ? dataArray.filter((item) => !valueArray.some((value) => value.id === item.id))
        : [...dataArray, ...missingItems];
};

const AssetRow = React.memo(
    ({
        headerList,
        dataItem,
        cellDataCb,
        selected,
        setSelected,
        selection,
        excluded,
        setExcluded,
        downloadItems,
        onRowClick,
        storeName,
        subContainer,
        isCollapsible,
        withCheckboxColumnNames,
        titleWithExpandIcon,
    }) => {
        const [isRowCollapsed, setIsRowCollapsed] = useState(dataItem.isRowCollapsed);
        const classes = useStyles();
        const getDataForCell = (headerItem, dataItem) => {
            if (cellDataCb) {
                return cellDataCb(headerItem, dataItem);
            } else {
                return (
                    <Typography variant="body2">
                        {<OverflowTip>{dataItem[headerItem.dataIndex] || ''}</OverflowTip>}
                    </Typography>
                );
            }
        };

        const handleCheckBoxClick = (e, value, columnTitle) => {
            e.stopPropagation();
            let newSelected;
            if (titleWithExpandIcon === columnTitle) {
                newSelected = updateListBulk(selected, subContainer);
            } else {
                const valueObj = subContainer.find((el) => el.id === value.id);
                newSelected = updateList(selected, valueObj);
                if (!e.target.checked) {
                    let newExcluded = updateList(excluded, valueObj);
                    setExcluded(newExcluded);
                }
            }
            setSelected(newSelected);
            selection.dispatchFn(
                selection.callbackFn(storeName, 'downloadItems', {
                    requestFlag: downloadItems.requestFlag,
                    localSelectAll: false,
                }),
            );
        };

        const isSelected = (dataItem, columnTitle) => {
            if (titleWithExpandIcon === columnTitle) {
                return subContainer.every((el) => selected.some((sel) => sel.id === el.id));
            }
            return selected.some((sel) => sel.id === dataItem.id);
        };

        const isIndeterminate = (columnTitle) => {
            if (titleWithExpandIcon === columnTitle) {
                const allSubsSelected = subContainer.every((el) => selected.indexOf(el.id) !== -1);
                if (allSubsSelected) {
                    return !allSubsSelected;
                }
                return subContainer.some((el) => selected.indexOf(el.id) !== -1);
            }
        };

        const handleRowClick = (notCollapsible, dataItemLocal) => {
            if (!notCollapsible) {
                setIsRowCollapsed(!dataItemLocal.isRowCollapsed);
                dataItemLocal.isRowCollapsed = !dataItemLocal.isRowCollapsed;
            }
            if (onRowClick?.onRowClickCb)
                onRowClick.onRowClickCb(dataItemLocal[onRowClick.rowDataField]);
        };

        const checkIfColumnWithCheckbox = (headerItem, columnHasValue) => {
            const columnTitle = headerItem.title.toLowerCase();
            if (withCheckboxColumnNames.includes(columnTitle) && columnHasValue) {
                return true;
            }
            return false;
        };

        const RowCheckbox = ({ dataItemLoc, columnTitle }) => (
            <Checkbox
                className={classes.checboxRow}
                color="primary"
                checked={isSelected(dataItemLoc, columnTitle)}
                indeterminate={isIndeterminate(columnTitle)}
                onClick={(event) => handleCheckBoxClick(event, dataItemLoc, columnTitle)}
            />
        );

        const DefaulfCell = ({ header, index, dataItem }) => {
            const columnTitle = header.title.toLowerCase();
            const coulumnValue = dataItem[header.dataIndex];
            const isColumnWithCheckbox = checkIfColumnWithCheckbox(header, Boolean(coulumnValue));
            return (
                <TableCell
                    className={`${classes.bodyCell}`}
                    align={header.hasNumericData ? 'right' : header.hasIcon ? 'center' : 'left'}
                    key={`${index}-${header.dataIndex}`}
                >
                    <div className={isColumnWithCheckbox ? classes.cellWithCheckbox : ''}>
                        {titleWithExpandIcon === columnTitle && Boolean(coulumnValue) && (
                            <ExpandMore
                                className={classNames(classes.expandIcon, {
                                    [classes.expanded]: isRowCollapsed,
                                    [classes.collapsed]: !isRowCollapsed,
                                })}
                            />
                        )}
                        {isColumnWithCheckbox && selection.isSelectable && (
                            <RowCheckbox dataItemLoc={dataItem} columnTitle={columnTitle} />
                        )}
                        {getDataForCell(header, dataItem)}
                    </div>
                </TableCell>
            );
        };

        const generateRowContent = ({ data }) => {
            return headerList.map((header, ind) => (
                <DefaulfCell header={header} index={ind} dataItem={data} />
            ));
        };

        const RowLine = ({ elSub, notCollapsible }) => {
            const data = elSub ? elSub : dataItem;

            return (
                <TableRow
                    className={`${classes.bodyRow}`}
                    onClick={() => handleRowClick(notCollapsible, data)}
                >
                    {generateRowContent({ data })}
                </TableRow>
            );
        };

        if (isCollapsible && isRowCollapsed) {
            const subRows = subContainer.map((elSub) => {
                return <RowLine elSub={elSub} notCollapsible={true} />;
            });
            return [<RowLine />, ...subRows];
        } else {
            return <RowLine />;
        }
    },
);

const useStylesSortIcon = makeStyles({
    icon: {
        '&& .MuiTableSortLabel-icon': {
            margin: (props) => `${props.hasNumericData ? '0 8px 0 0' : '0 0 0 8px'} !important`,
        },
    },
});

const CustomTableSortLabel = (props) => {
    const classes = useStylesSortIcon(props.hasNumericData);

    return (
        <TableSortLabel {...props} className={classes.icon}>
            {props.children}
        </TableSortLabel>
    );
};

const CellsForfullTable = React.memo(({ headerList }) => {
    if (headerList.length === 1)
        return (
            <>
                <TableCell></TableCell> <TableCell></TableCell>
            </>
        );
    if (headerList.length < 3) return <TableCell></TableCell>;
});

export default function CommonCollapsibleTableGrid({
    // TBD: To add proptypes to component props and mark required ones
    headerList,
    dataList, // necessarily should contain 'content' property as main array for iterations
    // dataList should contain following properties if paginationBE is true {content, totalElements, pageable: {pageNumber, pageSize}
    cellDataCb, // optional param, in case need to wrap cell into some component/tag
    loading,
    preferences, // obligatorily to provide if paginationBE is true
    paginationBE,
    searchTerm,
    selection, // provide object with dispatch and callback fns if checkbox selection is available
    rowsPerPageOff,
    tablePaginationOff,
    subRowsContainerFieldName, // should be provided only when at least one item from headers contains hasSubRow: true
    titleWithExpandIcon,
    additionalHeaderContent,
}) {
    const {
        loadDataByPageCb: { argumentsList, dispatchFn, callbackFn },
        onRowClick,
    } = preferences;
    // obligatorily: selected/setSelected should be created by UseRef hook
    const {
        selectedRows: selected = [],
        setSelectedRows: setSelected,
        excludedRows: excluded = [],
        setExcludedAssets: setExcluded,
        downloadItems: downloadItems = {},
        storeName,
        withCheckboxColumnNames,
    } = selection;
    const classes = useStyles();
    const [page, setPage] = useState(dataList?.pageable?.pageNumber || 0);
    const [rowsPerPage, setRowsPerPage] = useState(dataList?.pageable?.pageSize || 10);
    const [orderBy, setOrderBy] = useState('yearQuarterKey'); // to add generic value: very first key from headers list
    const [order, setOrder] = useState('asc');
    const [data, setData] = useState([]);
    const [isHeaderCheckBoxDisabled, setIsHeaderCheckBoxDisabled] = useState(false);

    const makeLocalPagination = ({ localRowsPerPage = rowsPerPage } = {}) => {
        if (!paginationBE) {
            const pagedArray = dataList.content.slice(
                page * localRowsPerPage,
                page * localRowsPerPage + localRowsPerPage,
            );
            setData(pagedArray);
        }
    };

    useEffect(() => {
        setRowsPerPage(dataList?.pageable?.pageSize || 10);
        if (dataList.content) {
            setData(dataList.content);
            if (selection.isSelectable && dataList.content?.length === 0) {
                setSelected([]);
                setIsHeaderCheckBoxDisabled(true);
            }
            if (downloadItems?.requestFlag || downloadItems?.localSelectAll) {
                addToSelectedIfMissing(dataList.content);
            }
            // This if block makes local pagination work if it is not provided server side (from BE)
            makeLocalPagination();
        } else if (!('content' in dataList) && selection.isSelectable) {
            setSelected([]);
            setIsHeaderCheckBoxDisabled(true);
        }
    }, [dataList.content]);

    useEffect(() => {
        makeLocalPagination();
    }, [page]);

    useEffect(() => {
        if (dataList.content.length === 0) setIsHeaderCheckBoxDisabled(false);
    }, [dataList.content]);

    const addToSelectedIfMissing = (dataToSearch) => {
        let newSelected = [];
        dataToSearch.map((rowItem) => {
            rowItem[subRowsContainerFieldName].map((dataItem) => {
                const value = dataItem;
                const selectedIndex = selected.indexOf(value);
                const excludedIndex = excluded.indexOf(value);
                if (selectedIndex === -1 && excludedIndex === -1) {
                    newSelected.push(value);
                }
            });
        });
        setSelected(selected.concat(newSelected)); // works because of ref
    };

    const handleChangePage = (event, newPage) => {
        if (downloadItems?.requestFlag || downloadItems?.localSelectAll) {
            addToSelectedIfMissing(dataList.content);
        }
        setPage(newPage);
        if (paginationBE) {
            const callBackParams = [
                ...argumentsList,
                {
                    page: newPage,
                    size: rowsPerPage,
                    ...(searchTerm && { searchTerm: searchTerm }),
                },
            ];
            dispatchFn(callbackFn(...callBackParams));
        }
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
        if (paginationBE) {
            const callBackParams = [
                ...argumentsList,
                {
                    page: 0,
                    size: event.target.value,
                    ...(searchTerm && { searchTerm: searchTerm }),
                },
            ];
            dispatchFn(callbackFn(...callBackParams));
        } else {
            makeLocalPagination({
                localRowsPerPage: newRowsPerPage,
            });
        }
    };
    const sortingAlgorithm = (a, b) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    function descendingComparator(a, b, orderBy) {
        if (a[orderBy]) {
            return sortingAlgorithm(a, b);
        } else {
            // this is case when sorting only nested lines not to mix the order of collapsible rows (e.g Quarter)
            b[subRowsContainerFieldName].sort(
                order === 'desc'
                    ? (a, b) => sortingAlgorithm(a, b)
                    : (a, b) => -sortingAlgorithm(a, b),
            );
            a[subRowsContainerFieldName].sort(
                order === 'desc'
                    ? (a, b) => sortingAlgorithm(a, b)
                    : (a, b) => -sortingAlgorithm(a, b),
            );
            return 0;
        }
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const isAllRowsSelected = () => {
        const allIds = data
            .map((item) => item[subRowsContainerFieldName])
            .flat()
            .map((el) => el.id);
        if (allIds.length === 0) return false;
        const isAllSelected = allIds.every((id) => selected.some((sel) => sel.id === id));
        if (isAllSelected) {
            return true;
        } else {
            return (
                (downloadItems.localSelectAll && data.length > 0) ||
                (data.length > 0 && selected.length === dataList.totalElements)
            );
        }
    };

    const isAllIndeterminate = () => {
        if (isAllRowsSelected()) {
            return false;
        } else {
            return (
                !downloadItems.localSelectAll &&
                selected.length > 0 &&
                selected.length !== dataList.totalElements
            );
        }
    };

    const getHeaderContent = (headerItem, index) => {
        return (
            <div>
                {index === 0 && selection.isSelectable && (
                    <Checkbox
                        className={classes.checboxRow}
                        color="primary"
                        indeterminate={isAllIndeterminate()}
                        checked={isAllRowsSelected()}
                        onChange={handleSelectAllClick}
                        disabled={isHeaderCheckBoxDisabled}
                    />
                )}
                <CustomTableSortLabel
                    hasNumericData={headerItem.hasNumericData}
                    style={{
                        flexDirection: headerItem.hasNumericData ? 'row-reverse' : 'inherit',
                    }}
                    active={orderBy === headerItem.dataIndex}
                    direction={orderBy === headerItem.dataIndex ? order : 'asc'}
                    onClick={createSortHandler(headerItem.dataIndex)}
                >
                    {headerItem.title}
                </CustomTableSortLabel>
            </div>
        );
    };

    const handleSelectAllClick = (event) => {
        event.stopPropagation();
        if (event.target.checked) {
            const newSelecteds = data
                .map((infoItem) =>
                    infoItem[subRowsContainerFieldName].map((subContainer) => ({
                        id: subContainer.id,
                        other: 'object part',
                    })),
                )
                .flat();
            setSelected(newSelecteds);
            setExcluded([]);
            let isDownloadAll = true;
            selection.dispatchFn(
                selection.callbackFn(storeName, 'downloadItems', {
                    requestFlag: isDownloadAll,
                    localSelectAll: true,
                }),
            );
        } else {
            setSelected([]);
            selection.dispatchFn(
                selection.callbackFn(storeName, 'downloadItems', {
                    requestFlag: false,
                    localSelectAll: false,
                }),
            );
        }
    };

    const getSelectedCount = () => {
        let count = 0;
        if (downloadItems.requestFlag) {
            if (excluded.length > 0) {
                if (paginationBE) count = dataList.totalElements - excluded.length;
                else count = selected.length;
            } else {
                if (paginationBE) count = dataList.totalElements;
                else count = selected.length;
            }
        } else {
            count = selected.length;
        }
        return count;
    };

    const getData = () => {
        if (data.length === 1) {
            data[0][subRowsContainerFieldName].sort(
                order === 'desc'
                    ? (a, b) => sortingAlgorithm(a, b)
                    : (a, b) => -sortingAlgorithm(a, b),
            );
            return data;
        } else {
            return data.sort(getComparator(order, orderBy));
        }
    };

    return (
        <>
            <Table className={classes.collapsibleCell} style={{ position: 'relative' }}>
                <TableHead className={`${classes.header}`}>
                    <TableRow>
                        {headerList.map((header, ind) => {
                            return (
                                <TableCell
                                    align={header.hasNumericData ? 'right' : 'left'}
                                    key={`${header.dataIndex}-${ind}`}
                                    // row below to keep table scaleing (needs dinamic update instead)
                                    className={ind === 1 ? classes.headerCellFixed : ''}
                                >
                                    <Typography
                                        variant="subtitle2"
                                        className={classNames({
                                            [classes.headerWithAdditionalContent]:
                                                additionalHeaderContent.callBack &&
                                                additionalHeaderContent.titleWithAddition ===
                                                    header.title,
                                        })}
                                    >
                                        {getHeaderContent(header, ind)}
                                        {additionalHeaderContent.callBack &&
                                            additionalHeaderContent.callBack(header)}
                                    </Typography>
                                </TableCell>
                            );
                        })}
                        <CellsForfullTable headerList={headerList} />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading && (
                        <LoaderOverlayRow>
                            <LoaderOverlayCell colSpan={headerList.length + 1}>
                                <CircularProgress />
                            </LoaderOverlayCell>
                        </LoaderOverlayRow>
                    )}
                    {!loading && data.length === 0 && (
                        <TableRow>
                            <TableCell colSpan={headerList.length + 1} align="center">
                                No data
                            </TableCell>
                        </TableRow>
                    )}
                    {getData().map((el, ind) => {
                        const subContainer = el[subRowsContainerFieldName];
                        const isCollapsible = !!subContainer;
                        return (
                            <AssetRow
                                key={`${ind}-${el[subRowsContainerFieldName][0]}`}
                                dataItem={el}
                                headerList={headerList}
                                cellDataCb={cellDataCb}
                                selected={selected}
                                setSelected={setSelected}
                                selection={selection}
                                excluded={excluded}
                                setExcluded={setExcluded}
                                downloadItems={downloadItems}
                                onRowClick={onRowClick}
                                storeName={storeName}
                                subRowsContainerFieldName={subRowsContainerFieldName}
                                subContainer={subContainer}
                                isCollapsible={isCollapsible}
                                withCheckboxColumnNames={withCheckboxColumnNames}
                                titleWithExpandIcon={titleWithExpandIcon}
                            />
                        );
                    })}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell
                            sx={{
                                border: 'none',
                                textAlign: 'right',
                                '& p': {
                                    width: '130px',
                                },
                            }}
                        >
                            {selection.isSelectable && (
                                <Typography variant="body1">{`Selected: (${getSelectedCount()})`}</Typography>
                            )}
                        </TableCell>
                        <TableCell
                            sx={{
                                border: 'none',
                            }}
                        />
                        {!tablePaginationOff && (
                            <TablePagination
                                rowsPerPageOptions={rowsPerPageOff ? [] : [10, 20, 50, 100]}
                                slotProps={{
                                    select: {
                                        inputProps: {
                                            'aria-label': 'rows per page',
                                        },
                                        native: true,
                                    },
                                }}
                                sx={{
                                    '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows':
                                        {
                                            margin: 0,
                                            padding: 0,
                                        },
                                    '& .MuiNativeSelect-select': {
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                    },
                                }}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                colSpan={10}
                                count={dataList.totalElements || dataList?.content?.length || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        )}
                    </TableRow>
                </TableFooter>
            </Table>
        </>
    );
}
